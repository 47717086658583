import logo from "./logo.svg";
import "./App.css";
import home from "./image/home-img.png";
import Tax from "./Tax";
import Home from "./Home";
function App() {
  return (
   
      <div className="App">
   <Home />
      
      </div>
  );
}

export default App;
